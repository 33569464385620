var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "v_ar_scheme d-flex flex-column bbox",
    },
    [
      _c("div", { staticClass: "tab_group bbox d-flex align-center" }, [
        _c(
          "div",
          {
            staticClass: "tab",
            class: {
              on: _vm.status === 1,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.changeStatus(1)
              },
            },
          },
          [_vm._v("\n      已发布\n    ")]
        ),
        _c(
          "div",
          {
            staticClass: "tab",
            class: {
              on: _vm.status === 0,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.changeStatus(0)
              },
            },
          },
          [_vm._v("\n      草稿箱\n    ")]
        ),
      ]),
      _vm.showDetail
        ? _c(
            "div",
            { staticClass: "navigator_group d-flex align-center bbox" },
            [
              _c(
                "div",
                {
                  staticClass: "name",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.goToAll.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.status ? "已发布" : "草稿箱") +
                      "\n    "
                  ),
                ]
              ),
              _c("div", { staticClass: "text" }, [_vm._v("\n      >\n    ")]),
              _c("div", { staticClass: "name" }, [
                _vm._v("\n      " + _vm._s(_vm.sceneData.name) + "\n    "),
              ]),
            ]
          )
        : _vm._e(),
      _c("div", { ref: "content_group", staticClass: "content_group bbox" }, [
        _c(
          "div",
          { staticClass: "scene_group" },
          [
            _vm._l(_vm.sceneList, function (item, index) {
              return [
                item.planList && item.planList.length
                  ? _c("div", { key: index }, [
                      !_vm.showDetail
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "scene_name_group d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "scene_name",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.goToMore(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              item.planList.length >= _vm.maxNum
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "more cp",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.goToMore(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              更多>\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "scheme_list d-flex flex-wrap align-center",
                        },
                        [
                          _vm._l(item.planList, function (scheme, schemeIndex) {
                            return [
                              (
                                _vm.showDetail
                                  ? 1
                                  : schemeIndex + 1 <= _vm.maxNum
                              )
                                ? _c(
                                    "div",
                                    {
                                      key: schemeIndex,
                                      staticClass: "scheme flex-shrink-0",
                                    },
                                    [
                                      _c("SchemeComponent", {
                                        attrs: {
                                          scheme: scheme,
                                          scene: item,
                                          "show-cover": Boolean(1),
                                          "show-delete": !Boolean(_vm.status),
                                          "show-offline": Boolean(_vm.status),
                                          "show-publish": !Boolean(_vm.status),
                                          "show-edit": Boolean(1),
                                        },
                                        on: {
                                          coverHandler: _vm.openCoverDialog,
                                          publishHandler: _vm.openPublishDialog,
                                          deleteHandler: _vm.openDeleteDialog,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ],
                        2
                      ),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
      _c(
        "modal",
        { attrs: { name: "coverDialog", height: "auto" } },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "编辑封面" },
              on: { closeHandler: _vm.closeCoverDialog },
            },
            [
              _c("CoverScheme", {
                attrs: { scheme: _vm.editData, scene: _vm.editScene },
                on: { closeHandler: _vm.closeCoverDialog },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        { attrs: { name: "publishDialog", height: "auto", width: "300px" } },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "提示" },
              on: { closeHandler: _vm.closePublish },
            },
            [
              _c("PublishDialog", {
                attrs: {
                  scheme: _vm.editData,
                  scene: _vm.editScene,
                  status: _vm.publish,
                },
                on: { closeHandler: _vm.closePublish },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "modal",
        { attrs: { name: "deleteDialog", height: "auto", width: "300px" } },
        [
          _c(
            "JsModal",
            {
              attrs: { title: "提示" },
              on: { closeHandler: _vm.closeDeleteDialog },
            },
            [
              _c("DeleteDialog", {
                attrs: { scheme: _vm.editData, scene: _vm.editScene },
                on: { closeHandler: _vm.closeDeleteDialog },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }